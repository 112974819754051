<template>
    <tabla-general 
    :usar-servidor="true" 
    :mostrarBuscador="false" 
    altura-tabla="calc(100vh - 390px)" 
    :servidor-data="materials"
    @paginar="$emit('paginar', $event)"
    >
        <el-table-column label="Referencia" prop="referencia" width="120">
            <template slot-scope="scope">
                <p class="f-12 text-muted2 tres-puntos">{{ scope.row.referencia }}</p>
            </template>
        </el-table-column>
        <el-table-column label="Nombre" prop="nombre">
            <template slot-scope="scope">
                <p class="f-12 text-muted2 tres-puntos cr-pointer f-600" @click="viewMaterialInfo(scope.row.id_material)">
                    {{ scope.row.nombre || '-'}}
                </p>
            </template>
        </el-table-column>
        <el-table-column label="U.M." prop="unidad_medida" width="80">
            <template slot-scope="scope">
                <p class="f-12 text-muted2">
                    {{ scope.row.unidad_medida || '-' }}
                </p>
            </template>
        </el-table-column>
        <el-table-column label="Valor cotizado" prop="valorCotizado" width="130" align="center">
            <template slot-scope="scope">
                <p class="f-12 text-muted2">
                    {{formatoMoneda(scope.row.valor_teorico)}}
                </p>
            </template>
        </el-table-column>
        <el-table-column label="Proveedor favorito" align="left" header-align="center"  width="130">
            <template slot-scope="scope">
                <p class="f-12 text-muted2">
                    {{ scope.row.proveedor || '-' }}
                </p>
            </template>
        </el-table-column>
        <el-table-column label="Etapas" prop="numEtapas" width="100" align="center">
            <template slot-scope="scope">
                <p class="f-12 text-muted2">
                    {{ scope.row.etapas}}
                </p>
            </template>
        </el-table-column>
        <el-table-column label="Plan de mantenimiento" prop="planMantenimiento" width="150" align="center">
            <template slot-scope="scope">
                <p>{{ scope.row.planes_mantenimiento || '-'}}</p>
            </template>
        </el-table-column>
        <el-table-column label="Estado" width="80" align="center">
            <template slot-scope="scope">
                <i :class="`icon-circle-fill f-20 ${scope.row.estado === 0  ? 'state__inactive' : 'state__active'} `" />
            </template>
        </el-table-column>
        <el-table-column width="60" align="center">
            <template slot-scope="scope">
                <i class="icon-trash-can-outline cr-pointer f-22" 
                    :class="permitAction('almacen.categorias.eliminar.material') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click="functionPermitAction('almacen.categorias.eliminar.material', ()=>$emit('delete', scope.row.id))"
                />
            </template>
        </el-table-column>
    </tabla-general>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            
        }
    },
    computed:{
        ...mapGetters({
			user: 'auth/user',
            materials: 'almacen/categorias/materiales/materials'
        }),
    },
    methods: {
        viewMaterialInfo(id){
            this.$router.push({ name: 'almacen.materiales.ver.datos', params: { id }});
        }
    }
}
</script>