<template>
    <Modal titulo="Agregar material" ref="modalAddMaterial" tamano="modal-md" adicional="Guardar" @adicional="saveMaterials">
        <div class="modal-agregar-material row justify-content-center">
            <div class="col-10 mb-4">
                <div class="row align-items-center">
                    <div class="col-3">
                        <label class="f-12 text-general f-500" for="material">Agregue material</label>
                    </div>
                    <div class="col-9">
                        <el-select v-model="selected_materials" multiple id="material">
                            <el-option v-for="material in select_materiales_disabled" 
                            :key="material.id"
                            :label="material.nombre" 
                            :value="material.id"
                            :disabled="material.disabled"
                            class="option-agregar-material"
                            > 
                                <div 
                                class="d-flex justify-content-between px-3"
                                :class="{'disabled': material.disabled}"
                                @click="material.selected = !material.selected"
                                >
                                    <label 
                                    class="text-general" 
                                    :class="{'cr-not-allowed': material.disabled}"
                                    v-text="material.nombre"
                                    />
                                    <el-checkbox
                                    v-if="!material.disabled"
                                    style="pointer-events: none"
                                    v-model="material.selected"
                                    :checked="material.selected" 
                                    />
                                </div>
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            selected_materials: [],
        }
    },
    computed:{
        ...mapGetters({
            materials: 'almacen/categorias/materiales/materials',
            materials_selected: 'almacen/categorias/materiales/selected_materials'
        }),
        select_materiales: {
            get(){
                return this.$store.getters['selects/selects/select_materials']
            },
            set(val){
                this.$store.commit('selects/selects/set_select_materials', val)
            }
        },
        category_id(){
            return this.$route.params.id_categoria
        },
        select_materiales_disabled(){
            return this.select_materiales.map(el => ({...el, disabled: this.materials_selected.includes(el.id)}))
        }
    }, 
    methods: {
        ...mapActions({
            addMaterials: 'almacen/categorias/materiales/Action_add_category_material',
            Selected_mataerials: 'almacen/categorias/materiales/Selected_mataerials'
        }),
        toggle(){
            this.selected_materials = []
            this.select_materiales = this.select_materiales.map(({selected, ...rest}) => ({...rest, selected: false}))
            this.$refs.modalAddMaterial.toggle();
        },
        async saveMaterials(){
            await this.addMaterials({
                category_id: this.$route.params.id_categoria,
                id_materiales: this.selected_materials,
            });
            await this.Selected_mataerials(this.category_id)
            this.toggle();
        },
    }
}
</script>

<style lang="scss">
.cr-na{
    cursor: not-allowed;
}
.option-agregar-material{
    .disabled{
        background-color: #EAF2FF
    }
}
</style>
