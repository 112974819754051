<template>
    <modal-lateral ref="modalFilterMaterial" titulo="Filtro">
        <div class="row mx-0 justify-content-center my-2">
            <div class="col-11 my-4">
                <p class="input-label-top">Tipo de material</p>
                <el-select v-model="filter.id_tipo_material" clearable filterable placeholder="Seleccionar unidad de medida" size="small" class="w-100">
                    <el-option v-for="item in material_types" :key="item.id" :label="item.tipo_material" :value="item.id" />
                </el-select>
            </div>
            <div class="col-11 my-4">
                <p class="input-label-top">Unidad de medida</p>
                <el-select v-model="filter.id_unidad_medida" clearable filterable placeholder="Seleccionar unidad de medida" size="small" class="w-100">
                    <el-option v-for="item in measure_units" :key="item.id" :label="item.unidad_medida" :value="item.id" />
                </el-select>
            </div>
            <div class="col-11 my-3">
                <p class="input-label-top">Rango de valor</p>
                <el-slider v-model="range_value" range :min="range[0]" :max="range[1]" :format-tooltip="formatTooltipRange" :disabled="disableSlider" />
            </div>
            <div class="col-11 my-4">
                <p class="input-label-top">Estado del material</p>
                    <el-checkbox-group v-model="filter.estado_material" class="mt-1" fill="#0B56A7">
                    <el-checkbox :label="1">Activo</el-checkbox>
                    <el-checkbox :label="0">Inactivo</el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        <div class="botones">
            <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="reload">Limpiar</button>
            <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterMaterials">Filtrar</button>
        </div>
    </modal-lateral>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
        }
    },
    computed:{
        ...mapGetters({
            range: 'almacen/categorias/materiales/range',
            material_types: 'selects/selects/select_material_types',
            measure_units: 'selects/selects/select_measure_units',
        }),
        filter:{
            get(){
                return this.$store.getters['almacen/categorias/materiales/filter']
            },
            set(val){
                this.$store.commit('almacen/categorias/materiales/set_filter', val)
            }
        },
        range_value: {
            get(){
                return [ this.filter.valor_minimo, this.filter.valor_maximo]   
            },
            set(val){
                this.filter.valor_minimo = val[0]
                this.filter.valor_maximo = val[1]
            }
        },
        disableSlider(){
            return this.range.every(el => el === this.range[0] && el === this.range[1])
        },
        category_id(){
            return this.$route.params.id_categoria
        }
    },
    methods: {
        ...mapActions({
            getCategoryMaterials: 'almacen/categorias/materiales/Action_get_category_materials_list',
            clearFilter: 'almacen/categorias/materiales/clear_filter'
        }),
        toggle(){
            this.$refs.modalFilterMaterial.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        reload(){
            this.clearFilter()
            this.getCategoryMaterials({ category_id: this.category_id })
        },
        async filterMaterials(){
            await this.getCategoryMaterials({ category_id: this.category_id })
            this.toggle()
        },
    }
}
</script>