<template>
    <section class="categorias-materiales px-3 container-fluid">
        <titulo-divisor>
            <template slot="titulo">
                <div class="col-auto my-auto d-middle-center cr-pointer">
                    <p class="f-600 f-14 ucfirst" @click="navigateTo({ name: 'almacen.categorias' })">
                        Categorias
                    </p>
                </div>
                <div class="col-auto my-auto d-middle-center cr-default p-0">
                    <i class="icon-right-open f-600 f-14"/>
                </div>
                <div class="col-auto my-auto d-middle-center cr-pointer text-general">
                    <p class="f-600 f-14 ucfirst" @click="navigateTo({ name: 'almacen.categorias.materiales', params: { id_categoria: $route.params.id_categoria } })">
                        {{ $route.params.name }}
                    </p>
                </div>
            </template>
            <div class="row">
                <el-tooltip content="Agregar material" effect="light" placement="top" visible-arrow>
                    <div class="col-auto icon-option my-auto px-1">
                        <i class="icon-plus-circle f-30"
                            :class="permitAction('almacen.categorias.agregar.material') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.categorias.agregar.material', openModalAddMaterial)"
                        />
                    </div>
                </el-tooltip>
                <div v-if="filterCount == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="openModalFilterModal">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="filterCount > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="openModalFilterModal">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2 cr-pointer">
                            <p class="f-12">{{ filterCount }}</p>
                            <i class="icon-close-circle f-11 text-muted pl-1" @click="reload" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <material-list @delete="handleDeleteCategory" @paginar="getCategoryMaterials({ category_id, page: $event})"/>
        <modal-add-material ref="modalAddMaterial"/>
        <modal-filter-material ref="modalFilterMaterial"/>
        <modal-eliminar 
        ref="modalEliminar" 
        title="Eliminar material" 
        mensaje="¿Desea eliminar este material?"
        @delete="beforeDeleteCategory"
        />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MaterialList from './materialList'
import modalAddMaterial from './partials/modalAddMaterial'
import modalFilterMaterial from './partials/modalFilterMaterial'
export default {
    components: { MaterialList, modalAddMaterial, modalFilterMaterial },
    data(){
        return {
            material: new Object(),
            field: ''
        }
    },
    computed:{
        ...mapGetters({
			user: 'auth/user',
            filter: 'almacen/categorias/materiales/filter'
        }),
        filterCount(){
            return this.filter.count();
        },
        category_id(){
            return this.$route.params.id_categoria
        }
    },
    async created(){
        this.getBreadcumbs([ 'almacen.main', 'almacen.categorias', 'almacen.categorias.materiales' ]);
        this.getSelects(['Action_get_select_materials', 'Action_get_select_material_types', 'Action_get_select_measure_units'])
        await this.Selected_mataerials(this.category_id)
        await this.getCategoryMaterialsRange(this.category_id)
        this.getCategoryMaterials({ category_id: this.category_id})
    },
    methods: {
        ...mapActions({
            getCategoryMaterials: 'almacen/categorias/materiales/Action_get_category_materials_list',
            getCategoryMaterialsRange: 'almacen/categorias/materiales/Action_get_category_material_range',
            Selected_mataerials: 'almacen/categorias/materiales/Selected_mataerials',
            deleteCategoryMaterial: 'almacen/categorias/materiales/Action_delete_category_material',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            clearFilter: 'almacen/categorias/materiales/clear_filter',
            getSelects: 'selects/selects/sync',
        }),
        createMaterial(){

        },
        openModalFilterModal(){
            this.$refs.modalFilterMaterial.toggle()
        },
        openModalAddMaterial(){
            this.$refs.modalAddMaterial.toggle()
        },
        handleDeleteCategory(id){
            this.material = { id }
            this.$refs.modalEliminar.toggle()
        },
        beforeDeleteCategory(){
            
            this.deleteCategoryMaterial({
                category_id: this.category_id, 
                category_material_id: this.material.id,
            })
        },
        async reload(){
            await this.clearFilter()
            this.getCategoryMaterials({ category_id: this.category_id})
        },
        goCategories(){

        },
        goCategoryMaterials(){
            this.navigateTo({ name: 'almacen.categorias.materiales', params: { id_categoria: this.category_id } })
        }
    }
}
</script>